import { Box, Button, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import styles from "./home.module.scss";
import commonStyle from "../../styles/commonStyles.module.scss";
import Star2 from "./../../assets/images/Star2.png";

const SubFooter = () => {
  return (
    <section
      className={classNames(
        commonStyle.contentWidth,
        commonStyle.alignJustifyCenter,
        styles.subFooterWrapper
      )}
    >
      <Box
        className={classNames(
          styles.contentWrapper,
          commonStyle.alignJustifyStart,
          commonStyle.gap290
        )}
      >
        <Box
          className={classNames(
            commonStyle.flexDirectionColumnStart,
            commonStyle.gap24
          )}
        >
          <Typography variant="head3">Secure Your Future <br /> Today</Typography>
          <Typography variant="subHead">
            Customize your coverage to shield your business from the
            ever-changing world of AI with our tailored insurance solutions.
          </Typography>

          <Button variant="circularButton" className={styles.learnMoreBtn}>
            Get Your Quote
          </Button>
        </Box>
        <Box>
          <img alt="img" src={Star2} />
        </Box>
      </Box>
    </section>
  );
};

export default SubFooter;

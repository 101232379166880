import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LinkIcon from "../../assets/icons/linkIcon";
import StarIcon from "../../assets/icons/starIcon";
import commonStyle from "../../styles/commonStyles.module.scss";
import styles from "./header.module.scss";

const SubMenuContent = ({ item, urlMap }) => {
  const navigateTo = useNavigate();
  const midpoint = item.length > 4 ? Math.ceil(item.length / 2) : 0;
  const subMenu = midpoint
    ? [item.slice(0, midpoint), item.slice(midpoint, item.length)]
    : [item];

  const handleClick = (val) => {
    navigateTo("/" + val);
  };

  return (
    <Box className={classNames(commonStyle.alignStart, commonStyle.gap40)}>
      {subMenu.map((ele, index) => (
        <Box key={index} className={styles.subHeadMenu}>
          {ele.map((subItem, i) => (
            <Box
              key={i}
              className={classNames(
                commonStyle.alignJustifyStart,
                commonStyle.gap14,
                styles.subMenuBox,
                urlMap === subItem.mapKey ? styles.active : ""
              )}
              onClick={() => handleClick(subItem.route)}
            >
              <Box>
                <StarIcon className={styles.starIcon} />
              </Box>
              <Box
                className={classNames(
                  commonStyle.alignJustifySpaceBetween,
                  commonStyle.gap16,
                  commonStyle.w100
                )}
              >
                <Typography variant="content5">{subItem.key}</Typography>
                <Box>
                  <LinkIcon className={styles.linkIcon} />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

const MenuItem = ({ item }) => {
  const contentRef = useRef(null);
  const location = useLocation();
  const pathname = location.pathname;
  const segments = pathname.split("/");
  const lastSegment = segments[segments.length - 1];

  const handleMenuItemClick = (menuItem, isShow) => {
    return () => {
      //   if (menuItem.hasChild) {
      if (contentRef.current) {
        contentRef.current.classList.toggle(styles.showContainer, isShow);
      }
      //   } else {
      //     navigateTo(menuItem.url);
      //   }
    };
  };

  return (
    <Box style={{ height: "100%" }}>
      <Box
        className={classNames(commonStyle.alignCenter, styles.dropdown)}
        onMouseOver={handleMenuItemClick(item, true)}
        onMouseOut={handleMenuItemClick(item, false)}
      >
        <Typography
          className={classNames(
            styles.menuHeadLabel,
            item.mapKey && item.mapKey.indexOf(lastSegment) > -1 ? styles.active : ""
          )}
          variant="content3"
        >
          {item.head}
        </Typography>
        <Box ref={contentRef} className={styles.dropdownContent}>
          <Box className={styles.dropdownContainer}>
            <Box
              className={classNames(
                commonStyle.flexDirectionColumnStart,
                commonStyle.gap8,
                styles.subMenuWrapper
              )}
            >
              <Typography
                className={classNames(styles.subMenuHeadLabel)}
                variant="content8"
              >
                {item.head}{" "}
              </Typography>
              <Box className={classNames(commonStyle.alignJustifyCenter)}>
                <SubMenuContent item={item.subHead} urlMap={lastSegment} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MenuItem;

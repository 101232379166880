import React from "react";

const StarIcon = ({ color = "#DADAF5", className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className}
    >
      <path
        d="M8.06974 1.50087C8.40266 0.65672 9.59734 0.656721 9.93026 1.50087L11.6691 5.9097C11.7707 6.16742 11.9747 6.37143 12.2325 6.47307L16.6413 8.21189C17.4854 8.54481 17.4854 9.73949 16.6413 10.0724L12.2325 11.8112C11.9747 11.9129 11.7707 12.1169 11.6691 12.3746L9.93026 16.7834C9.59734 17.6276 8.40266 17.6276 8.06974 16.7834L6.33092 12.3746C6.22927 12.1169 6.02527 11.9129 5.76755 11.8112L1.35872 10.0724C0.51457 9.73949 0.51457 8.54481 1.35872 8.21189L5.76755 6.47307C6.02527 6.37143 6.22927 6.16742 6.33092 5.9097L8.06974 1.50087Z"
        fill={color}
      />
    </svg>
  );
};

export default StarIcon;

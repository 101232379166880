import React from "react";

const ArrowLeft = ({ color = "#DADAF5", className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="vuesax/linear/arrow-left">
        <g id="arrow-left">
          <path
            id="Vector"
            d="M9.57 5.93018L3.5 12.0002L9.57 18.0702"
            stroke="#2D2D2D"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_2"
            d="M20.4999 12H3.66992"
            stroke="#2D2D2D"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default ArrowLeft;

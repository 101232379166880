import React from "react";

const Info = ({ color = "#4E4AFF", className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="vuesax/bold/info-circle">
        <g id="info-circle">
          <path
            id="Vector"
            d="M9.99999 1.66675C5.40832 1.66675 1.66666 5.40841 1.66666 10.0001C1.66666 14.5917 5.40832 18.3334 9.99999 18.3334C14.5917 18.3334 18.3333 14.5917 18.3333 10.0001C18.3333 5.40841 14.5917 1.66675 9.99999 1.66675ZM9.37499 6.66675C9.37499 6.32508 9.65832 6.04175 9.99999 6.04175C10.3417 6.04175 10.625 6.32508 10.625 6.66675V10.8334C10.625 11.1751 10.3417 11.4584 9.99999 11.4584C9.65832 11.4584 9.37499 11.1751 9.37499 10.8334V6.66675ZM10.7667 13.6501C10.725 13.7584 10.6667 13.8417 10.5917 13.9251C10.5083 14.0001 10.4167 14.0584 10.3167 14.1001C10.2167 14.1417 10.1083 14.1667 9.99999 14.1667C9.89166 14.1667 9.78332 14.1417 9.68332 14.1001C9.58332 14.0584 9.49166 14.0001 9.40832 13.9251C9.33332 13.8417 9.27499 13.7584 9.23332 13.6501C9.19166 13.5501 9.16666 13.4417 9.16666 13.3334C9.16666 13.2251 9.19166 13.1167 9.23332 13.0167C9.27499 12.9167 9.33332 12.8251 9.40832 12.7417C9.49166 12.6667 9.58332 12.6084 9.68332 12.5667C9.88332 12.4834 10.1167 12.4834 10.3167 12.5667C10.4167 12.6084 10.5083 12.6667 10.5917 12.7417C10.6667 12.8251 10.725 12.9167 10.7667 13.0167C10.8083 13.1167 10.8333 13.2251 10.8333 13.3334C10.8333 13.4417 10.8083 13.5501 10.7667 13.6501Z"
            fill="#FDC241"
          />
        </g>
      </g>
    </svg>
  );
};

export default Info;

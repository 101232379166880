import React from "react";

const Close = ({ color = "#4E4AFF", className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="vuesax/bold/close-circle">
        <g id="close-circle">
          <path
            id="Vector"
            d="M9.99999 1.66675C5.40832 1.66675 1.66666 5.40841 1.66666 10.0001C1.66666 14.5917 5.40832 18.3334 9.99999 18.3334C14.5917 18.3334 18.3333 14.5917 18.3333 10.0001C18.3333 5.40841 14.5917 1.66675 9.99999 1.66675ZM12.8 11.9167C13.0417 12.1584 13.0417 12.5584 12.8 12.8001C12.675 12.9251 12.5167 12.9834 12.3583 12.9834C12.2 12.9834 12.0417 12.9251 11.9167 12.8001L9.99999 10.8834L8.08332 12.8001C7.95832 12.9251 7.79999 12.9834 7.64166 12.9834C7.48332 12.9834 7.32499 12.9251 7.19999 12.8001C6.95832 12.5584 6.95832 12.1584 7.19999 11.9167L9.11666 10.0001L7.19999 8.08342C6.95832 7.84175 6.95832 7.44175 7.19999 7.20008C7.44166 6.95842 7.84166 6.95842 8.08332 7.20008L9.99999 9.11675L11.9167 7.20008C12.1583 6.95842 12.5583 6.95842 12.8 7.20008C13.0417 7.44175 13.0417 7.84175 12.8 8.08342L10.8833 10.0001L12.8 11.9167Z"
            fill="#E54848"
          />
        </g>
      </g>
    </svg>
  );
};

export default Close;

import React, { useEffect, useRef, useState } from "react";

import SubFooter from "./../homepage/subFooter";
// import commonStyle from "../../styles/commonStyles.module.scss";
import styles from "./aboutUs.module.scss";
import { Box } from "@mui/material";
import Footer from "./../footer/index";
import Header from "./../header/index";
import SectionAboutUsOne from "./sectionAboutUsOne";
import SectionAboutUsTwo from "./sectionAboutUsTwo";
import SectionAboutUsThree from "./sectionAboutUsThree";
import UserData from "../../dialogs/userData";
import { useLocation } from "react-router-dom";

const AboutUs = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;
  const segments = pathname.split("/");
  const lastSegment = segments[segments.length - 1];

  // const visionRef = useRef(null);
  const missionRef = useRef(null);
  const teamRef = useRef(null);

  useEffect(() => {
    // Scroll to the top of the section when the route changes
    switch (lastSegment) {
      case "team":
        teamRef.current?.scrollIntoView({
          behavior: "smooth",
          block: 'start', inline: 'nearest',
          offsetTop: 1000,
        });
        break;
      case "mission":
        missionRef.current?.scrollIntoView({
          behavior: "smooth",
        });
        break;
      default:
        console.log("No scroll");
    }
  }, [lastSegment]);

  return (
    <div className={styles.aboutUsPageWrapper}>
      <Box className={styles.headerWrapper}>
        <Header
          buttonName={"Get in Touch"}
          action={{ onClick: () => setOpen((prev) => !prev) }}
        />
        <SectionAboutUsOne />
      </Box>
      <SectionAboutUsTwo ref={missionRef} />
      <SectionAboutUsThree ref={teamRef} />
      <SubFooter />
      <Footer />
      <UserData open={open} toggleDrawer={(val) => setOpen(val)} />
    </div>
  );
};

export default AboutUs;

import { Box, Button } from "@mui/material";
import classNames from "classnames";
import React from "react";
import commonStyle from "../../styles/commonStyles.module.scss";
import styles from "./header.module.scss";
import LogoImage from "./../../assets/images/Logo.svg";
import { headLinks } from "../../utils/data";
import MenuItem from "./menuItem";
import { useNavigate } from "react-router-dom";
const Header = ({ buttonName, action }) => {
  const navigateTo = useNavigate();

  return (
    <section
      className={classNames(
        commonStyle.contentWidth,
        styles.headerContentWrapper
      )}
    >
      <Box className={classNames(commonStyle.justifySpaceBetween)}>
        <Box
          className={styles.logoImageWrapper}
          onClick={() => navigateTo("/")}
        >
          <img alt="img" src={LogoImage} />
        </Box>

        <Box className={styles.menuHeadWrapper}>
          {headLinks.map((item, index) => (
            <MenuItem key={index} item={item} />
          ))}
        </Box>
        <Box className={commonStyle.alignCenter}>
          <Button variant="circularButton" className={styles.gsBtn} {...action}>
            {buttonName ? buttonName : " Get Started"}
          </Button>
        </Box>
      </Box>
    </section>
  );
};

export default Header;

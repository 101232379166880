import { createTheme } from "@mui/material/styles";

const fontFamilyNames = ["Inter", "sans-serif"].join(",");
const theme = createTheme({
  typography: {
    fontFamily: fontFamilyNames,
    head: {
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "700",
      fontTransform: "uppercase",
      letterSpacing: "1.44px",
    },
    head1: {
      fontSize: "56px",
      fontStyle: "normal",
      fontWeight: "600",
    },
    head2: {
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: "600",
    },
    head3: {
      fontSize: "52px",
      fontStyle: "normal",
      fontWeight: "600",
    },
    content1: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "30px",
    },
    content2: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    content3: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "24px",
    },
    content4: {
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
    },
    content5: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
    },
    content6: {
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
    content7: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
    content8: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    content9: {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
    subHead: {
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "26px",
    },
    subHead2: {
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "26px",
    },
    subHead3: {
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "26px",
    },
    subHead4: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
    subHead5: {
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    subHead6: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    tabHead: {
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "42px",
    },
    tabContent: {
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "39px",
    },
    h2: {
      lineHeight: "3.25rem",
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontSize: "2rem",
      lineHeight: "2.75rem",
    },
    h5: {
      fontSize: "1rem",
      lineHeight: "2.5rem",
    },
    h6: {
      fontSize: "1em",
      lineHeight: "1rem",
    },
    subtitle1: {
      fontWeight: 200,
    },
    body1: {
      fontSize: "1em",
    },
    body2: {
      fontSize: "0.86rem",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "circularButton" },
          style: {
            width: "fit-content",
            padding: "12px 24px;",
            borderRadius: "100px",
            background: "#4E4AFF",
            textTransform: "capitalize",
            fontSize: "16px",
            fontWeight: "500",
            color: "white",
            "&:hover": {
              background:
                "var(--Btn-grad, linear-gradient(89deg, #2D2D2D -15.5%, #504E9C 57.81%, #F1D5AC 102.91%))",
              color: "white",
            },
          },
        },
        {
          props: { variant: "circularButtonSmall" },
          style: {
            width: "fit-content",
            padding: "6px 12px;",
            borderRadius: "40px",
            background: "#4E4AFF",
            textTransform: "capitalize",
            fontSize: "14px",
            fontWeight: "500",
            color: "white",
            "&:hover": {
              background:
                "var(--Btn-grad, linear-gradient(89deg, #2D2D2D -15.5%, #504E9C 57.81%, #F1D5AC 102.91%))",
              color: "white",
            },
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined", // Set the default variant to outlined
      },
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            padding: "0px", // Modify the padding
          },
          "& .MuiOutlinedInput-root input": {
            padding: "12px", // Modify the padding
          },
          "& .MuiOutlinedInput-root textarea": {
            padding: "12px", // Modify the padding
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#4E4AFF", // Modify the focus color
            },
          "& .Mui-error": {
            borderColor: "#d32f2f", // Modify the error color
            marginLeft: "0px",
          },
        },
      },
    },
  },
});

export default theme;

import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import styles from "./home.module.scss";
import commonStyle from "../../styles/commonStyles.module.scss";

import { infoData } from "../../utils/data";

export const getFeedback = (item) => {
  return (
    <Box
      className={classNames(
        commonStyle.flexDirectionColumnStart,
        commonStyle.gap32,
        styles.feedbackContent
      )}
    >
      <Box
        className={classNames(commonStyle.alignJustifyCenter, commonStyle.w100)}
      >
        <Box className={classNames(commonStyle.alignCenter, styles.cImage)}>
          <img alt="img" src={item.companyImage} />
        </Box>
      </Box>
      <Box minHeight={"114px"} className={commonStyle.textAlignCenter}>
        <Typography color="#575668" lineHeight={"28px"} variant="content3">
          {item.feedback}
        </Typography>
      </Box>
      <Box>
        <Box
          className={classNames(
            commonStyle.alignJustifyCenter,
            commonStyle.gap24
          )}
        >
          <Box className={styles.authorImage}>
            <img alt="img" src={item.authorImage}></img>
          </Box>
          <Box className={commonStyle.flexDirectionColumnStart}>
            <Typography variant="content3" color="#717885">
              {item.name}
            </Typography>
            <Typography variant="content3" fontWeight={"600"}>
              {item.position}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SectionHomeFive = () => {
  return (
    <section
      className={classNames(
        commonStyle.contentWidth,
        styles.sectionHomeFiveWrapper
      )}
    >
      <Box
        className={classNames(
          commonStyle.flexDirectionColumn,
          commonStyle.gap72
        )}
      >
        <Box
          className={classNames(
            commonStyle.flexDirectionColumn,
            commonStyle.gap16
          )}
        >
          <Typography variant="head2" textAlign="center">
            Our AI risk insurance isn’t just trusted{" "}—{" "}it’s preferred
          </Typography>
          <Typography variant="content3" textAlign="center">
            Discover customer experiences secured by our commitment
          </Typography>
        </Box>
        <Box
          className={classNames(
            commonStyle.alignStart,
            commonStyle.gap21,
            styles.infoWrapper
          )}
        >
          {infoData.map((item, i) => (
            <Box key={i} className={styles.infoBox}>
              {getFeedback(item)}
            </Box>
          ))}
        </Box>
      </Box>
    </section>
  );
};

export default SectionHomeFive;

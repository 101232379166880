import React from "react";

const Verify = ({ color = "#DADAF5", className }) => {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="vuesax/bulk/verify">
        <g id="verify">
          <path
            id="Vector"
            d="M19.7083 4.99191C20.9733 3.91025 23.0449 3.91025 24.3283 4.99191L27.2249 7.48525C27.7749 7.96191 28.8016 8.34691 29.5349 8.34691H32.6516C34.5949 8.34691 36.1899 9.94191 36.1899 11.8852V15.0019C36.1899 15.7169 36.5749 16.7619 37.0516 17.3119L39.5449 20.2086C40.6266 21.4736 40.6266 23.5452 39.5449 24.8286L37.0516 27.7252C36.5749 28.2752 36.1899 29.3019 36.1899 30.0352V33.1519C36.1899 35.0952 34.5949 36.6902 32.6516 36.6902H29.5349C28.8199 36.6902 27.7749 37.0752 27.2249 37.5519L24.3283 40.0452C23.0633 41.1269 20.9916 41.1269 19.7083 40.0452L16.8116 37.5519C16.2616 37.0752 15.2349 36.6902 14.5016 36.6902H11.3299C9.38661 36.6902 7.79161 35.0952 7.79161 33.1519V30.0169C7.79161 29.3019 7.40661 28.2752 6.94828 27.7252L4.47328 24.8102C3.40995 23.5452 3.40995 21.4919 4.47328 20.2269L6.94828 17.3119C7.40661 16.7619 7.79161 15.7352 7.79161 15.0202V11.8669C7.79161 9.92358 9.38661 8.32858 11.3299 8.32858H14.5016C15.2166 8.32858 16.2616 7.94358 16.8116 7.46691L19.7083 4.99191Z"
            fill="#159D70"
          />
          <path
            id="Vector_2"
            d="M19.7822 28.3114C19.4155 28.3114 19.0672 28.1647 18.8105 27.908L14.3738 23.4714C13.8422 22.9397 13.8422 22.0597 14.3738 21.528C14.9055 20.9964 15.7855 20.9964 16.3172 21.528L19.7822 24.993L27.6655 17.1097C28.1972 16.578 29.0772 16.578 29.6088 17.1097C30.1405 17.6414 30.1405 18.5214 29.6088 19.053L20.7538 27.908C20.4972 28.1647 20.1488 28.3114 19.7822 28.3114Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};

export default Verify;

import { Box, Button, Typography } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import styles from "./home.module.scss";
import commonStyle from "../../styles/commonStyles.module.scss";
// import video from "./../../assets/video/Cert - GIF.mov";
import video from "./../../assets/video/Cert - Demo video 1.mov";
import Mintplex from "./../../assets/images/mintplex.png";
import Lumona from "./../../assets/images/lumona.png";
import Glimmer from "./../../assets/images/Glimmer.png";
import LogoImage from "./../../assets/images/Cert.svg";
import { ReactTyped } from "react-typed";
import UserData from "../../dialogs/userData";

const TypingAnimation = () => {
  const texts = [
    "Trusted Protection",
    "Expert Guidance",
    "Tailored Coverage",
    "Industry-Leading Support",
    "Trusted Partnerships",
  ];

  return (
    <ReactTyped
      strings={texts}
      typeSpeed={60}
      backSpeed={50}
      backDelay={3000}
      startDelay={500}
      showCursor={false}
      cursorChar=""
      attr={null}
      loop
    />
  );
};
const SectionHomeOne = () => {
  const [open, setOpen] = useState(false);

  return (
    <section
      className={classNames(
        commonStyle.flexDirectionColumn,
        styles.sectionHomeOneWrapper
      )}
    >
      <Box>
        <Box
          className={classNames(
            commonStyle.flexDirectionColumn,
            commonStyle.gap64
          )}
        >
          <Box
            className={classNames(
              commonStyle.flexDirectionColumn,
              commonStyle.gap32
            )}
          >
            <Box className={commonStyle.flexDirectionColumn}>
              <Typography variant="head1">
                Navigate AI Challenges with
              </Typography>
              <Box className={commonStyle.alignJustifyCenter}>
                <Box className={styles.logoImageWrapper}>
                  <img alt="img" src={LogoImage} />
                </Box>
                <Typography variant="head1" color="#4E4AFF">
                  <TypingAnimation />
                </Typography>
              </Box>
            </Box>
            <Typography variant="subHead4">
              Securing your AI assets with Customized Insurance Policies
            </Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap21
            )}
          >
            <Button style={{ padding: "12px 32px" }} variant="circularButton">
              Estimate my Coverage
            </Button>
            <Button
              style={{ padding: "12px 32px" }}
              variant="circularButton"
              className={styles.gitBtn}
              onClick={() => setOpen((prev) => !prev)}
            >
              Get In Touch
            </Button>
          </Box>
        </Box>

        <Box className={styles.imageWrapper}>
          <video autoPlay muted loop>
            <source src={video} />
          </video>
        </Box>
        <Box
          className={classNames(
            commonStyle.flexDirectionColumn,
            commonStyle.gap48,
            styles.partnerWrapper
          )}
        >
          <Typography variant="subHead">
            Celebrated by clients leveraging AI insurance solutions across
            diverse sectors and needs
          </Typography>
          <Box className={styles.partnerImages}>
          <Box className={styles.cImage}>
              <img alt="img" src={Mintplex} />
            </Box>
            <Box className={styles.cImage}>
              <img alt="img" src={Lumona} />
            </Box>
            <Box className={styles.cImage}>
              <img style={{objectFit: "cover"}} alt="img" src={Glimmer} />
            </Box>
            {/* <Box className={styles.cImage}>
              <img alt="img" src={Extrahop} />
            </Box>{" "}
            <Box className={styles.cImage}>
              <img alt="img" src={BrightHealthcare} />
            </Box>
            <Box className={styles.cImage}>
              <img alt="img" src={Coinme} />
            </Box>
            <Box className={styles.cImage}>
              <img alt="img" src={Bloom} />
            </Box> */}
          </Box>
        </Box>
      </Box>
      <UserData open={open} toggleDrawer={(val) => setOpen(val)} />
    </section>
  );
};

export default SectionHomeOne;

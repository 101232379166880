import React from "react";

const Correct = ({ color = "#4E4AFF", className }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="vuesax/bold/tick-circle">
        <g id="tick-circle">
          <path
            id="Vector"
            d="M10 2.05981C5.40833 2.05981 1.66667 5.80148 1.66667 10.3931C1.66667 14.9848 5.40833 18.7265 10 18.7265C14.5917 18.7265 18.3333 14.9848 18.3333 10.3931C18.3333 5.80148 14.5917 2.05981 10 2.05981ZM13.9833 8.47648L9.25833 13.2015C9.14167 13.3181 8.98334 13.3848 8.81667 13.3848C8.65 13.3848 8.49167 13.3181 8.375 13.2015L6.01667 10.8431C5.775 10.6015 5.775 10.2015 6.01667 9.95981C6.25833 9.71815 6.65833 9.71815 6.9 9.95981L8.81667 11.8765L13.1 7.59315C13.3417 7.35148 13.7417 7.35148 13.9833 7.59315C14.225 7.83482 14.225 8.22648 13.9833 8.47648Z"
            fill="#159D70"
          />
        </g>
      </g>
    </svg>
  );
};

export default Correct;

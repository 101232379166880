import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React, { forwardRef } from "react";
import commonStyle from "../../styles/commonStyles.module.scss";
import { teamData } from "../../utils/data";
import styles from "./aboutUs.module.scss";
import star2 from "./../../assets/images/Star2.png";

const TeamInfo = ({ item }) => {
  return (
    <Box className={styles.teamInfoWrapper}>
      <div className={styles.content}>
        <Box className={styles.contentWrapper}>
          <Box
            className={classNames(
              commonStyle.flexDirectionColumnStart,
              styles.contentText
            )}
          >
            <Typography variant="subHead6">{item.name}</Typography>
            <Typography color="#717885" variant="subHead4">
              {item.position}
            </Typography>
          </Box>
          <Box className={styles.image}>
            <img src={item.image} alt="img" />
          </Box>
        </Box>
      </div>
      <div className={styles.overlay}>
        <Box className={styles.overlayContent}>
          <Typography variant="content5"> {item.about}</Typography>
          <img alt="img" src={star2} />
        </Box>
      </div>
    </Box>
  );
};

const SectionAboutUsThree = forwardRef((props, ref) => {
  return (
    <section
      className={classNames(
        commonStyle.flexDirectionColumn,
        commonStyle.gap48,
        styles.sectionAboutUsThreeWrapper
      )}
      ref={ref}
    >
      <Box
        className={classNames(
          commonStyle.flexDirectionColumn,
          commonStyle.gap8,
          styles.textWrapper
        )}
      >
        <Typography variant="head3" className={styles.headText}>
          People behind the Tech
        </Typography>
        <Typography className={styles.subHeadText}>
          Collaboration with top people at each industry
        </Typography>
      </Box>
      <Box className={styles.teamContainer}>
        {teamData.map((item, index) => (
          <TeamInfo key={index} item={item} />
        ))}
      </Box>
    </section>
  );
});

export default SectionAboutUsThree;

import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import styles from "./home.module.scss";
import commonStyle from "../../styles/commonStyles.module.scss";
import { communityData } from "../../utils/data";

const renderContent = (item) => {
  return (
    <Box className={classNames(commonStyle.alignStart, commonStyle.gap14)}>
      <Box className={styles.iconImage}>
        <img alt="img" src={item.icon}></img>
      </Box>
      <Box
        className={classNames(
          commonStyle.flexDirectionColumnStart,
          commonStyle.gap8
        )}
      >
        <Typography variant="subHead3">{item.heading}</Typography>
        <Typography color="#717885" variant="content3">
          {item.content}
        </Typography>
      </Box>
    </Box>
  );
};

const SectionHomeSix = () => {
  return (
    <section className={classNames(styles.backgroundWrapper)}>
      <Box
        className={classNames(
          commonStyle.contentWidth,
          styles.sectionHomeSixWrapper
        )}
      >
        <Box
          className={classNames(
            commonStyle.flexDirectionColumn,
            commonStyle.gap80
          )}
        >
          <Box
            className={classNames(
              commonStyle.flexDirectionColumn,
              commonStyle.gap16
            )}
          >
            <Typography variant="head2" textAlign="center">
              Embark on Secure AI Practices with <br /> Insights from Cert’s Risk
              Management Hub
            </Typography>
            <Typography variant="content3" textAlign="center">
              Join a network of innovators in AI risk assurance, <br />
              Share insights, discover safeguards, and advance your protection
              strategies together.
            </Typography>
          </Box>
          <Box
            className={classNames(commonStyle.alignStart, styles.infoWrapper)}
          >
            {communityData.map((item, index) => (
              <Box key={index} className={styles.infoBox}>
                {renderContent(item)}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </section>
  );
};

export default SectionHomeSix;

import messageImg from "./../assets/images/messages.png";
import flashImg from "./../assets/images/flash.png";
import homewifiImg from "./../assets/images/home-wifi.png";
import sheildTickImg from "./../assets/images/shield-tick.png";
import docTextImg from "./../assets/images/document-text.png";
import deviceMessageImg from "./../assets/images/device-message.png";
import image104 from "./../assets/images/Meta Llama.png";
import image107 from "./../assets/images/Google lambda.png";
import image108 from "./../assets/images/openai.png";
import image109 from "./../assets/images/mistral.png";
import image28 from "./../assets/images/anaphero.png";
import image31 from "./../assets/images/Rove.png";
import image122 from "./../assets/images/image 168.png";
import image123 from "./../assets/images/image 170.png";
import image124 from "./../assets/images/image 169.png";
import image125 from "./../assets/images/image 171.png";
import CarlosZhang from "./../assets/images/Carlos Zhang.jpeg";
import Daniel from "./../assets/images/Daniel Vincent.jpeg";
import Sophia from "./../assets/images/Sophia Martins.jpeg";
import Issac from "./../assets/images/Issac Kim.jpeg";
import Nadia from "./../assets/images/Nadia Patel.jpeg";
import Mackenzie from "./../assets/images/Mackenzie Williams.jpeg";
import quote from "./../assets/images/quote-up.svg";
import Ian from "./../assets/images/Ian William Bancroft.jpeg";
import Seraphina from "./../assets/images/Seraphina Marquez.jpeg";
import BharatBhate from "./../assets/images/BharatBhate.png";
import Akash from "./../assets/images/AkashKumar.png";
import Satyam from "./../assets/images/SatyamKumar.png";
import Rachit from "./../assets/images/RachitJain.png";

export const feedback = [
  {
    companyImage: image28,
    feedback:
      "With Cert’s AI risk insurance, we have not only fortified our capabilities but also mitigated potential disruptions, allowing us to push the boundaries of medical innovation with AI. Thanks to their comprehensive coverage, we continue to lead in healthcare advancements while managing potential risks effectively.",
    authorImage: Seraphina,
    name: "Seraphina Marquez",
    position: "Risk Management Head, Anaphero",
  },
  {
    companyImage: image31,
    feedback:
      "Cert has been crucial in protecting our AI-driven financial models and trading algorithms, preventing costly interruptions and maintaining our reputation in the marketplace. Their deep understanding of AI risks not only protects our core operations but also ensures that our innovative approaches are well-protected against unforeseen AI-related challenges.",
    authorImage: Ian,
    name: "Ian William Bancroft",
    position: "Lead Actuarial Strategist, Rove",
  },
];

export const infoData = [
  {
    companyImage: quote,
    feedback:
      "Our journey with Cert has been pivotal. They've provided AI risk coverage that keeps pace with the rapid evolution of our tech. It's comforting to know that as we push boundaries, our risks are managed.",
    authorImage: Sophia,
    name: "Sophia Martins",
    position: "CEO of Tech Innovations",
  },

  {
    companyImage: quote,
    feedback:
      "Data is at the heart of what we do, and Cert's AI risk insurance has been a game-changer for us. It's comprehensive, designed for today's challenges, and backed by a team that truly understands AI.",
    authorImage: Daniel,
    name: "Daniel Vincent",
    position: "Chief Data Architect",
  },
  {
    companyImage: quote,
    feedback:
      "Our customers expect the best, and that includes how we use AI to serve them. Cert helped us insure against AI mishaps that could affect our customer relationships. It's been a wise move.",
    authorImage: Nadia,
    name: "Nadia Patel",
    position: "VP of Customer Experience",
  },
  {
    companyImage: quote,
    feedback:
      "Partnering with Cert means that we're not only insured, but we also gain insights into potential AI vulnerabilities. Their proactive approach to risk management is invaluable.",
    authorImage: Mackenzie,
    name: "Mackenzie Williams",
    position: "Compliance Officer",
  },
  {
    companyImage: quote,
    feedback:
      "Developing AI solutions comes with unique risks. Cert has provided us with the safety net we need, allowing us to innovate with confidence.",
    authorImage: CarlosZhang,
    name: "Carlos Zhang",
    position: " Lead AI Developr",
  },
  {
    companyImage: quote,
    feedback:
      "AI poses complex security challenges. Cert's insurance covers these nuances, ensuring that we are protected against both known and emerging threats.",
    authorImage: Issac,
    name: "Isaac Kim",
    position: "Director of IT Security",
  },
];

export const communityData = [
  {
    icon: messageImg,
    heading: "Expert community engagement",
    content:
      "Connect with global AI risk insurance experts to share insights and develop solutions.",
  },
  {
    icon: flashImg,
    heading: "AI risk Hub",
    content:
      "Access a comprehensive library of resources to guide you through AI risk management.",
  },
  {
    icon: sheildTickImg,
    heading: "Customized Insurance Policy ",
    content:
      "Explore tailored insurance policies designed specifically for AI-related risks.",
  },
  {
    icon: homewifiImg,
    heading: "Marketplace for Risk Solutions",
    content:
      "Discover a marketplace of risk management tools and services from trusted providers.",
  },
  {
    icon: docTextImg,
    heading: "Insights Blog",
    content:
      "Read expert articles and analyses on the latest in AI risks and insurance on our blog.",
  },
  {
    icon: deviceMessageImg,
    heading: "Interactive Workshops",
    content:
      "Join workshops with industry experts to learn about managing AI risks and navigating AI insurance.",
  },
];

export const tabData = [
  {
    labelImage: image108,
    heading:
      "“Saved approximately 30% in operational costs during the recovery.“",
    content:
      "Renowned for its human-like text generation, GPT is a leading language model. However, its capabilities also pose risks, such as biased or inappropriate outputs. Cert’s insurance provides financial protection for businesses utilizing GPT-3, guarding against liabilities stemming from misinformation. Additionally, we offer guidance on implementing best practices for data processing to effectively mitigate risks.",
    contentImg: image122,
  },
  {
    labelImage: image104,
    heading:
      "“Saved 60 days in legal proceedings and maintained productivity.“",
    content:
      "As it facilitates interactive learning from human feedback to enhance language understanding. However, this iterative learning process carries risks, including the reinforcement of incorrect information. Our AI insurance protects businesses leveraging LLaMA, offering coverage against liabilities arising from biased feedback loops. Furthermore, we provide guidance on responsible learning practices to mitigate risks effectively.",
    contentImg: image123,
  },
  {
    labelImage: image109,
    heading: "“Saved us 40% in legal expenses.“",
    content:
      "From Salesforce Research, excelling in real-time conversational responses but raises concerns regarding data privacy and misleading interactions. With our insurance solutions support businesses using Mistral by offering coverage for potential data breaches. Additionally, we provide guidance on implementing robust security measures to safeguard sensitive information.",
    contentImg: image124,
  },
  {
    labelImage: image107,
    heading:
      "“Received essential support for legal expenses and settlements, efficiently resolving our claims.“",
    content:
      "Google’s conversational AI model, it specializes in generating engaging dialogue but carries risks of user manipulation and bias amplification. Our AI insurance offerings mitigate these risks by providing coverage for legal liabilities. Furthermore, we offer guidance on ethical implementation practices to ensure responsible and unbiased interactions.",
    contentImg: image125,
  },
];

export const teamData = [
  {
    name: "Bharat Bhate ",
    position: "Co-Founder & CEO",
    about:
      "As the CEO, my goal is to establish our AI insurance business as a pioneer in using technology to revolutionize the insurance sector. Our goal is to satisfy our clients' changing needs by offering comprehensive, honest, and individualized insurance services. Trust, client happiness, and moral business conduct are important to us. Our goal is to provide our clients with a sense of security through dependable and effective insurance solutions, which is why we offer our goods and services.",
    image: BharatBhate,
  },
  {
    name: "Akash Kumar",
    position: "Co-Founder & CTO",
    about:
      "As CTO, I'm committed to leading the way in developing cutting-edge AI solutions that transform the insurance industry. My goal is to smoothly combine state-of-the-art technology with common insurance requirements while maintaining dependability and progressive flexibility. Our goal is to make insurance more accessible by utilizing AI to improve user experience and expedite claims procedures. I supervise the creation of services and products that are not just cutting edge in terms of technology but also safe, easy to use, and innovative, all while upholding the values of inclusivity, creativity, and integrity.",
    image: Akash,
  },
  {
    name: "Satyam Kumar Singh",
    position: "Co - Founder & CIO",
    about:
      "As the division's leader, my goal is to use AI to reinvent insurance procedures and provide unmatched service quality and claim efficiency. Our goal is to make every customer feel secure and appreciated by tailoring insurance solutions to meet their individual and business needs. We uphold the values of responsibility, openness, and justice. Our selection of goods and services is designed to improve client satisfaction and trust by offering quick, accurate, and equitable insurance solutions.",
    image: Satyam,
  },
  {
    name: "Rachit Jain",
    position: "Co - Founder & CSO",
    about:
      "As the Chief Strategy Officer at Cert, I am dedicated to driving innovation and strategic growth, positioning our company as a leader in the AI insurance sector. My role involves conducting rigorous market analysis to identify emerging opportunities and address potential challenges. I collaborate closely with our technology leaders to support our strategic initiatives, ensuring we maintain our competitive edge. I am committed to ensuring that our insurance solutions are not only innovative but also fully embody our core values of transparency and integrity. ",
    image: Rachit,
  },
];

export const headLinks = [
  {
    head: "Why Cert",
    subHead: [
      { key: "Predictive Analytics for AI Risk Management", route: "" },
      { key: "Expert AI Advisory Services", route: "" },
      { key: "AI Community Interactions", route: "" },
    ],
  },
  {
    head: "AI Risks",
    subHead: [
      { key: "Regulatory Violations", route: "" },
      { key: "Technical Errors", route: "" },
      { key: "Misrepresentation and False Advertising", route: "" },
      { key: "Liability for AI-Enabled Products and Services", route: "" },
      { key: "Cyber Intrusion", route: "" },
      { key: "Data Breach", route: "" },
      { key: "Algorithmic Bias & Discrimination", route: "" },
      { key: "Reputation", route: "" },
    ],
  },
  {
    head: "Coverages",
    subHead: [
      { key: "Cyber Liability Insurance", route: "" },
      { key: "Errors and Omissions Insurance", route: "" },
      { key: "Directors and Officers Liability Insurance", route: "" },
      { key: "Commercial General Liability Insurance", route: "" },
      { key: "Intellectual Property Insurance", route: "" },
      { key: "Product Liability Insurance", route: "" },
      { key: "Employment Practices Liability Insurance", route: "" },
      { key: "Media Liability Insurance", route: "" },
    ],
  },
  {
    head: "Services",
    subHead: [
      { key: "Customized Insurance Solutions", route: "" },
      { key: "AI Risk Assessment and Analysis", route: "" },
      { key: "Continuous Monitoring and Updates", route: "" },
      { key: "AI Risk Mitigation Strategies", route: "" },
      { key: "Claims Management and Support", route: "" },
      { key: "AI Incident Investigation", route: "" },
      { key: "Education and Training", route: "" },
      { key: "AI Risk Legal Advisory ", route: "" },
    ],
  },
  {
    head: "Claims",
    subHead: [
      { key: "Claims Dashboard", route: "" },
      { key: "File a Claim", route: "" },
      { key: "Track Your Claim", route: "" },
      { key: "Claim Record", route: "" },
      { key: "Claims Assistance Desk", route: "" },
      { key: "Claims FAQs", route: "" },
    ],
  },
  {
    head: "About Us",
    mapKey: ["team", "vision", "mission"],
    subHead: [
      { key: "Vision", route: "vision", mapKey: "vision" },
      { key: "Misson", route: "mission", mapKey: "mission" },
      { key: "Core Team", route: "team", mapKey: "team" },
    ],
  },
  // {
  //   head: "Team",
  //   subHead: [
  //     "AI Risk Analysts",
  //     "Head of Risk Management",
  //     "Advanced AI Insurance Advisors",
  //     "Cybersecurity Specialists",
  //     "Legal Experts",
  //     "Ethics Consultants",
  //     "Industry-Specific Experts",
  //     "Customer Relationship Director",
  //   ],
  // },
];

import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import styles from "./home.module.scss";
import commonStyle from "../../styles/commonStyles.module.scss";
import { feedback } from "../../utils/data";
import Close from "../../assets/icons/close";
import Info from "../../assets/icons/info";
import Correct from "../../assets/icons/correct";
import LogoImage from "./../../assets/images/Logo.svg";

const getMiddleSection = () => {
  return (
    <Box className={commonStyle.alignStart}>
      <Box style={{ width: "331px" }} className={styles.boxContent}>
        <Box className={styles.checkListHead}>
          <Typography className={styles.colHead1} variant="subHead5">
            Coverage area
          </Typography>
        </Box>
        <Box
          className={classNames(
            commonStyle.flexDirectionColumnStart,
            commonStyle.gap35,
            styles.checkListContent
          )}
        >
          <Typography variant="content4">AI Bias & Discrimination</Typography>
          <Typography variant="content4">AI IP Infringement Claims</Typography>
          <Typography variant="content4">AI Regulatory Violations </Typography>
          <Typography variant="content4">Third-Party Liability</Typography>
          <Typography variant="content4">AI Product Failures</Typography>
          <Typography variant="content4">AI Technical Errors</Typography>
          <Typography variant="content4">Other AI risks</Typography>
          <Typography variant="content4">
            Privacy Violations/Data Breaches
          </Typography>
          <Typography variant="content4">System Failures</Typography>
        </Box>
      </Box>
      <Box style={{ width: "370px" }} className={styles.boxContent}>
        <Box className={styles.checkListHead}>
          <Typography className={styles.colHead2} variant="subHead5">
            Standard Tech E&O Coverage
          </Typography>
        </Box>
        <Box
          className={classNames(
            commonStyle.flexDirectionColumnStart,
            commonStyle.gap33,
            styles.checkListContent
          )}
        >
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Close />
            </Box>
            <Typography variant="content5">Not covered</Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Close />
            </Box>
            <Typography variant="content5">Not covered</Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Close />
            </Box>
            <Typography variant="content5">Not covered</Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Info />
            </Box>
            <Typography variant="content5">
              Shared responsibility shield
            </Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Info />
            </Box>
            <Typography variant="content5">
              Not affirmatively covered
            </Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Info />
            </Box>
            <Typography variant="content5">
              Not affirmatively covered
            </Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Info />
            </Box>
            <Typography variant="content5">
              Explore beyond boundaries
            </Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Correct />
            </Box>
            <Typography variant="content5">Privacy shield</Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Correct />
            </Box>
            <Typography variant="content5">System safety net</Typography>
          </Box>
        </Box>
      </Box>
      <Box style={{ width: "463px" }}>
        <Box
          className={classNames(
            commonStyle.flexDirectionColumnStart,
            commonStyle.gap8,
            styles.checkListHead2
          )}
        >
          <Box className={styles.logoImageWrapper}>
            <img alt="img" src={LogoImage} />
          </Box>
          <Typography className={styles.colHead1} variant="subHead5">
            AI Insurance
          </Typography>
        </Box>
        <Box
          className={classNames(
            commonStyle.flexDirectionColumnStart,
            commonStyle.gap27,
            styles.checkListContent
          )}
        >
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Correct />
            </Box>
            <Typography variant="content5">
              Shields against claims alleging algorithmic bias
            </Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Correct />
            </Box>
            <Typography variant="content5">
              Protects against claims of AI product IP violations
            </Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Correct />
            </Box>
            <Typography variant="content5">
              Covers defense costs for AI-specific regulatory violations
            </Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Correct />
            </Box>
            <Typography variant="content5">
              Offers liability protection for third-party claims involving AI
              products or services.
            </Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Correct />
            </Box>
            <Typography variant="content5">
              Insures against losses from AI products or algorithms failures
            </Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Correct />
            </Box>
            <Typography variant="content5">
              Covers damages from AI technical errors
            </Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Correct />
            </Box>
            <Typography variant="content5">
              Coverage for broader AI-related risks like data breaches, privacy
              violations, and system failures
            </Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Correct />
            </Box>
            <Typography variant="content5">
              Potential coverage for privacy and data breaches linked to AI
              technologies
            </Typography>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap8
            )}
          >
            <Box className={commonStyle.alignCenter}>
              <Correct />
            </Box>
            <Typography variant="content5">
              Cover losses due to AI system malfunctions
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const getFeedback = (item) => {
  return (
    <div>
      <Box
        className={classNames(
          commonStyle.flexDirectionColumnStart,
          commonStyle.gap32,
          styles.feedbackContent
        )}
      >
        <Box
          style={{ width: item.width }}
          className={classNames(commonStyle.alignCenter, styles.cImage)}
        >
          <img alt="img" src={item.companyImage} />
        </Box>

        <Box style={{ minHeight: "180px" }}>
          <Typography variant="subHead">{item.feedback}</Typography>
        </Box>
        <Box>
          <Box
            className={classNames(commonStyle.alignStart, commonStyle.gap24)}
          >
            <Box className={styles.authorImage}>
              <img alt="img" src={item.authorImage}></img>
            </Box>
            <Box className={commonStyle.flexDirectionColumnStart}>
              <Typography variant="subHead">{item.name}</Typography>
              <Typography variant="subHead" fontWeight={"600"}>
                {item.position}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

const SectionHomeTwo = () => {
  return (
    <>
      <Box
        className={classNames(
          commonStyle.flexDirectionColumn,
          styles.sectionHomeTwoWrapperOne,
          commonStyle.contentWidth
        )}
      >
        <Box>
          <Box
            className={classNames(
              commonStyle.flexDirectionColumnStart,
              commonStyle.gap12
            )}
          >
            <Typography variant="head2">
              Empower your business with <br /> Cert's advanced AI protection
            </Typography>
            <Typography variant="content3">
              Outdated solutions can’t match our safeguarding for your future
            </Typography>
          </Box>

          <Box className={styles.image}>{getMiddleSection()}</Box>
        </Box>
      </Box>
      <Box className={styles.sectionHomeTwoWrapperTwo}>
        <Box
          className={classNames(
            commonStyle.alignJustifyCenter,
            commonStyle.gap200
          )}
        >
          {feedback.map((item) => getFeedback(item))}
        </Box>
      </Box>
    </>
  );
};

export default SectionHomeTwo;

import { Box, Tab, Tabs, Typography } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import styles from "./home.module.scss";
import commonStyle from "../../styles/commonStyles.module.scss";
import { tabData } from "../../utils/data";

const TabPanel = (props) => {
  const {
    children,
    value,
    index,
    tabLableIdentifier,
    tabPanelLableIdentifier,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`Tab-${index}`}
      aria-labelledby={`Tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const SectionHomeThree = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (newValue) => {
    setTabValue(newValue);
  };

  return (
    <section className={classNames(styles.sectionHomeThreeWrapper, commonStyle.contentWidth)}>
      <Box
        className={classNames(
          commonStyle.flexDirectionColumn,
          commonStyle.gap72
        )}
      >
        <Box
          className={classNames(
            commonStyle.flexDirectionColumn,
            commonStyle.gap16
          )}
        >
          <Typography variant="head2">
            Protect against the risks of AI
          </Typography>
          {/* <Typography variant="Content3">
            Clients trust our AI insurance to navigate the digital landscape
            with confidence
          </Typography> */}
        </Box>
        <Box className={classNames(commonStyle.flexDirectionColumn)}>
          <Tabs>
            {tabData.map((item, index) => (
              <Tab
                className={classNames(
                  styles.tabLabel,
                  tabValue === index && styles.activeTab
                )}
                key={index}
                label={
                  <Box className={styles.imageContainer}>
                    <img
                      className={styles.image}
                      alt="tabImg"
                      src={item.labelImage}
                    />
                  </Box>
                }
                value={tabValue ? tabValue : 0}
                onClick={() => handleChange(index)}
              ></Tab>
            ))}
          </Tabs>
          <Box className={styles.tabContentWrapper}>
            {tabData.map((item, index) => (
              <TabPanel key={index} index={index} value={tabValue || 0}>
                <Box
                  className={classNames(
                    commonStyle.justifySpaceBetween,
                    commonStyle.gap120
                  )}
                >
                  <Box
                    className={classNames(
                      commonStyle.flexDirectionColumnStart,
                      commonStyle.gap24
                    )}
                  >
                    {/* <Typography variant="tabHead" className={styles.tabHead}>
                      {item.heading}
                    </Typography> */}
                    <Typography
                      variant="subHead"
                      className={styles.tabContent}
                    >
                      {item.content}
                    </Typography>
                  </Box>
                  <Box className={styles.contentImg}>
                    <img src={item.contentImg} alt="contentImg" />
                  </Box>
                </Box>
              </TabPanel>
            ))}
          </Box>
        </Box>
      </Box>
    </section>
  );
};

export default SectionHomeThree;

import { Box, Button, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import styles from "./home.module.scss";
import commonStyle from "../../styles/commonStyles.module.scss";
import image from "../../assets/images/InsureToday.png";

const SectionHomeFour = () => {
  return (
    <section
      className={classNames(
        commonStyle.alignJustifyCenter,
        styles.sectionHomeFourWrapper,
        commonStyle.contentWidth
      )}
    >
      <Box
        className={classNames(styles.contentWrapper, commonStyle.alignStart)}
      >
        <Box
          style={{ maxWidth: "532px" }}
          className={classNames(
            commonStyle.flexDirectionColumnStart,
            commonStyle.gap36
          )}
        >
          <Typography variant="head3">
            Insure Today, Innovate Tomorrow
          </Typography>
          <Typography variant="subHead">
            Welcome to Your AI Risk Management and Protection Hub. Seamlessly
            Navigate Through a Panorama of Insurance Coverage Options, Uniting
            Your Policies to Scale Your Business in an AI-Driven World.
          </Typography>

          <Button variant="circularButton" className={styles.learnMoreBtn}>
            Learn More
          </Button>
        </Box>
        <Box className={styles.contentImage}>
          <img alt="img" src={image} />
        </Box>
      </Box>
    </section>
  );
};

export default SectionHomeFour;

import { Box } from "@mui/material";
import React from "react";
import SectionHomeFive from "./sectionHomeFive";
import SectionHomeFour from "./sectionHomeFour";
import SectionHomeOne from "./sectionHomeOne";
import SectionHomeSix from "./sectionHomeSix";
import SectionHomeThree from "./sectionHomeThree";
import SectionHomeTwo from "./sectionHomeTwo";
import SubFooter from "./subFooter";
import styles from "./home.module.scss";
import Header from "../header";
import Footer from "../footer";

const HomePage = () => {
  return (
    <div className={styles.homePageWrapper}>
      <Box className={styles.headerWrapper}>
        <Header />
        <SectionHomeOne />
      </Box>
      <SectionHomeTwo />
      <SectionHomeFour />
      <SectionHomeThree />
      <SectionHomeFive />
      <SectionHomeSix />
      <SubFooter />
      <Footer />
    </div>
  );
};

export default HomePage;

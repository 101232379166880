import React from "react";

const LinkIcon = ({ color = "#B1B7C4", className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      className={className}
    >
      <path
        d="M11.9972 9.13348L19.1508 9.13348L19.1508 16.287"
        stroke={color}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.13342 19.1508L19.0506 9.23365"
        stroke={color}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LinkIcon;

import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import commonStyle from "../../styles/commonStyles.module.scss";
import styles from "./footer.module.scss";
import LogoImage from "./../../assets/images/Logo.svg";
import { headLinks } from "../../utils/data";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigateTo = useNavigate();
  return (
    <div className={classNames(styles.backgroundWrapper)}>
      <section
        className={classNames(commonStyle.contentWidth, styles.footerWrapper)}
      >
        <Box className={classNames(commonStyle.alignJustifySpaceBetween)}>
          <Box
            className={classNames(
              commonStyle.flexDirectionColumnStart,
              commonStyle.gap16
            )}
          >
            <Box
              className={styles.logoImageWrapper}
              onClick={() => navigateTo("/")}
            >
              <img alt="img" src={LogoImage} />
            </Box>
            <Box>
              <Typography variant="content7" color="#9292B8">
                © Cert, Inc. All rights reserved
              </Typography>
            </Box>
          </Box>
          <Box
            className={classNames(
              commonStyle.alignJustifyCenter,
              commonStyle.gap32
            )}
          >
            {headLinks.map((item, index) => (
              <Typography
                key={index}
                className={styles.footerLink}
                color="#9292B8"
                variant="content6"
              >
                {item.head}
              </Typography>
            ))}
          </Box>
        </Box>

        {/* <Box className={classNames(commonStyle.flexDirectionColumnStart, commonStyle.gap44)}>
          <Box
            className={classNames(commonStyle.alignStart, commonStyle.gap20)}
          >
            {headLinks.slice(0, 3).map((item) => (
              <Box
                className={classNames(
                  commonStyle.flexDirectionColumnStart,
                  commonStyle.gap14,
                  styles.content
                )}
              >
                <Typography variant="subHead3">{item.head}</Typography>
                {item.subHead.map((item) => (
                  <Typography variant="subHead">{item}</Typography>
                ))}
              </Box>
            ))}
          </Box>
          <Box
            className={classNames(commonStyle.alignStart, commonStyle.gap20)}
          >
            {headLinks.slice(0, 3).map((item) => (
              <Box
                className={classNames(
                  commonStyle.flexDirectionColumnStart,
                  commonStyle.gap14,
                  styles.content
                )}
              >
                <Typography variant="subHead3">{item.head}</Typography>
                {item.subHead.map((item) => (
                  <Typography variant="subHead">{item}</Typography>
                ))}
              </Box>
            ))}
          </Box>
        </Box> */}
      </section>
    </div>
  );
};

export default Footer;

import {
  Box,
  Button,
  Checkbox,
  Drawer,
  // FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import styles from "./dialog.module.scss";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import commonStyle from "../styles/commonStyles.module.scss";
import ArrowLeft from "../assets/icons/arrowLeft";
import logo from "../assets/images/Logo.svg";
import Verify from "../assets/icons/verify";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  bName: Yup.string().required("Busiess Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  address: Yup.string().required("Address is required"),
  number: Yup.string()
    .matches(/^\+1 [0-9]{10}$/, "Invalid contact number")
    .required("Contact number is required"),
  checkBox: Yup.boolean().oneOf([true], "Please check the field"),
});

const initialValues = {
  name: "",
  bName: "",
  email: "",
  address: "",
  number: "+1 ",
  checkBox: false,
};

const FormWithValidations = ({ handleSubmit }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form className={styles.columnSpaceBetween}>
          <Box className={classNames(styles.formWrapper)}>
            <Box
              className={classNames(
                commonStyle.flexColumn,
                commonStyle.gap8,
                commonStyle.w100
              )}
            >
              <Typography variant="content7">Name </Typography>
              <Field
                as={TextField}
                name="name"
                placeholder="John Doe"
                variant="outlined"
                fullWidth
                error={errors.name && touched.name}
                helperText={errors.name && touched.name ? errors.name : ""}
                className={styles.inputContent}
              />
            </Box>
            <Box
              className={classNames(
                commonStyle.flexColumn,
                commonStyle.gap8,
                commonStyle.w100
              )}
            >
              <Typography variant="content7">Business Name </Typography>
              <Field
                as={TextField}
                name="bName"
                placeholder="Business Ltd"
                variant="outlined"
                fullWidth
                error={errors.bName && touched.bName}
                helperText={errors.bName && touched.bName ? errors.bName : ""}
                className={styles.inputContent}
              />
            </Box>
            <Box
              className={classNames(
                commonStyle.flexColumn,
                commonStyle.gap8,
                commonStyle.w100
              )}
            >
              <Typography variant="content7">Business Email </Typography>
              <Field
                as={TextField}
                name="email"
                type="email"
                placeholder="john@domain.com"
                variant="outlined"
                fullWidth
                error={errors.email && touched.email}
                helperText={errors.email && touched.email ? errors.email : ""}
                className={styles.inputContent}
              />
            </Box>
            <Box
              className={classNames(
                commonStyle.flexColumn,
                commonStyle.gap8,
                styles.w48
              )}
            >
              <Typography variant="content7">Business Address </Typography>
              <Field
                as={TextField}
                name="address"
                placeholder="Start typing"
                variant="outlined"
                multiline
                rows={1}
                rowsMax={3}
                fullWidth
                error={errors.address && touched.address}
                helperText={
                  errors.address && touched.address ? errors.address : ""
                }
                className={styles.inputContent}
              />
            </Box>
            <Box
              className={classNames(
                commonStyle.flexColumn,
                commonStyle.gap8,
                styles.w48
              )}
            >
              <Typography variant="content7">Contact Number </Typography>
              <Field
                as={TextField}
                name="number"
                placeholder="some"
                variant="outlined"
                fullWidth
                error={errors.number && touched.number}
                helperText={
                  errors.number && touched.number ? errors.number : ""
                }
                className={styles.inputContent}
              />
            </Box>
          </Box>
          <Box
            className={classNames(
              commonStyle.flexColumn,
              commonStyle.gap8,
              styles.btnWrapper
            )}
          >
            <Box className={classNames(commonStyle.alignJustifyStart)}>
              <Field
                as={Checkbox}
                name="checkBox"
                error={errors.checkBox && touched.checkBox}
                helperText={
                  errors.checkBox && touched.checkBox ? errors.checkBox : ""
                }
                className={styles.checkBox}
              />
              <Typography htmlFor="checkBox" variant="content9">
                I understand that this form collects my personal information for
                potential contact
              </Typography>
              {/* <FormControlLabel
                control={<Checkbox name="checkBox" />}
                label="I understand that this form collects my personal information for
                potential contact"
                className={styles.checkBox}
              />
              {errors.checkBox && touched.checkBox && (
                <div style={{ color: "red" }}>{errors.checkBox}</div>
              )} */}
            </Box>
            <Button
              type="submit"
              variant="circularButtonSmall"
              className={styles.submitBtn}
              color="primary"
            >
              Submit
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

const UserData = ({ open, toggleDrawer }) => {
  const [isDone, setDone] = useState(false);

  const handleSubmit = (values) => {
    setTimeout(() => {
      setDone(true);
    }, 1000);
  };

  const handleClose = () => {
    setDone(false);
    toggleDrawer(false);
  };

  return (
    <Drawer
      open={open}
      anchor="right"
      PaperProps={{
        sx: { width: "706px" },
      }}
      onClose={handleClose}
    >
      <Box className={styles.userDataWrapper}>
        {isDone ? (
          <Box className={styles.columnSpaceBetween}>
            <Box className={styles.logoImage}>
              <img alt="img" src={logo} />
            </Box>
            <Box
              className={classNames(
                commonStyle.flexDirectionColumn,
                commonStyle.gap12
              )}
            >
              <Verify />
              <Typography variant="subHead6" textAlign={"center"}>
                We've received your details and will be in touch soon to tailor
                the perfect AI insurance solution for you.{" "}
              </Typography>
            </Box>
            <Box className={commonStyle.w100}>
              <Button
                variant="circularButtonSmall"
                className={styles.submitBtn}
                onClick={handleClose}
              >
                Done
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            className={classNames(
              commonStyle.flexColumn,
              commonStyle.gap36,
              commonStyle.h100
            )}
          >
            <Box className={commonStyle.alignJustifySpaceBetween}>
              <Box
                className={classNames(
                  commonStyle.alignJustifyCenter,
                  commonStyle.gap8
                )}
                style={{ cursor: "pointer" }}
                onClick={() => toggleDrawer(false)}
              >
                <ArrowLeft />
                <Typography variant="content8">Go back</Typography>
              </Box>
              <Box className={styles.logoImage}>
                <img alt="img" src={logo} />
              </Box>
            </Box>
            <Box
              className={classNames(
                commonStyle.flexColumn,
                commonStyle.gap22,
                commonStyle.h100
              )}
            >
              <Typography color="#7B61FF" variant="subHead3">
                User Details
              </Typography>
              <FormWithValidations handleSubmit={handleSubmit} />
            </Box>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default UserData;

import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import commonStyle from "../../styles/commonStyles.module.scss";
import styles from "./aboutUs.module.scss";
import image from "./../../assets/images/image 148.svg"

const SectionAboutUsOne = () => {
  return (
    <section
      className={classNames(
        commonStyle.flexDirectionColumn,
        styles.sectionAboutUsOneWrapper
      )}
    >
      <Box
        className={classNames(
          commonStyle.flexDirectionColumn,
          commonStyle.gap48
        )}
      >
        <Box className={classNames(commonStyle.flexDirectionColumn, commonStyle.gap24, styles.headWrapper)}>
          <Typography variant="head3">Vision</Typography>
          <Typography variant="content1" className={commonStyle.textAlignCenter}>
            <b>To lead the transformation of risk management in the AI era,</b>
            ensuring that innovation and ethics move hand in hand by providing
            robust insurance solutions that safeguard against AI-related
            uncertainties.
          </Typography>
        </Box>
        <Box className={styles.image}>
            <img alt="img" src={image}/>
        </Box>
      </Box>
    </section>
  );
};

export default SectionAboutUsOne;

// import logo from "./logo.svg";
import "./App.scss";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import HomePage from "./page/homepage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutUs from "./page/aboutUs";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path={"/team"} element={<AboutUs />} />
          <Route path={"/vision"} element={<AboutUs />} />
          <Route path={"/mission"} element={<AboutUs />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;

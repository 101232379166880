import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React, { forwardRef } from "react";
import commonStyle from "../../styles/commonStyles.module.scss";
import styles from "./aboutUs.module.scss";
import image from "./../../assets/images/image 144.svg";

const SectionAboutUsTwo = forwardRef((props, ref) => {
  return (
    <section
      className={classNames(
        commonStyle.alignJustifyCenter,
        commonStyle.gap72,
        styles.sectionAboutUsTwoWrapper
      )}
      ref={ref}
    >
      <Box
        className={classNames(
          commonStyle.flexDirectionColumnStart,
          commonStyle.gap24,
          styles.textWrapper
        )}
      >
        <Typography variant="head3">Mission</Typography>
        <Typography variant="content1">
          Our goal is to empower enterprises by providing specialized insurance
          services that guard against the unanticipated effects of artificial
          intelligence, promoting a more ethical, dependable, and safe
          technological future. We are dedicated to excellence, innovation, and
          integrity in every facet of our business operations in AI risk
          reduction.
        </Typography>
      </Box>
      <Box>
        <Box className={styles.image}>
          <img alt="img" src={image} />
        </Box>
      </Box>
    </section>
  );
});

export default SectionAboutUsTwo;
